import React, { useState } from "react";

const Accordion = ({ title, answer, className }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className={`p-5 ${className} rounded-r-3xl p-0`}>
      <button onClick={() => setAccordionOpen(!accordionOpen)} className="flex justify-between w-full md:px-10 uppercase rounded-r-lg">
        <span className="accordion-title text-xl md:text-3xl">{title}</span>
        {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
        <svg className="fill-black shrink-0 ml-8" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <rect y="5" width="16" height="6" rx="1" className={`transform origin-center transition duration-200 ease-out 
          ${accordionOpen && "!rotate-180"}`}/>
          <rect y="5" width="16" height="6" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out 
          ${accordionOpen && "!rotate-180"}`}
          />
        </svg>
      </button>
      <div className={`w-full grid overflow-hidden transition-all duration-300 ease-in-out text-left md:pl-10 md:pr-52 ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100 pt-5 pb-5"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden accordion-content sm:w-[600px]">{answer}</div>
      </div>
    </div>
  );
};

export default Accordion;