import React from 'react'
import './About.css'
import felixHogagen from '../../assets/images/about/Felix-Hohagen.png'
import singer from '../../assets/images/about/singer.png'
import singerMobile from '../../assets/images/about/singer-mobile.svg'
import boxer from '../../assets/images/about/boxer.png'
import unionImage from '../../assets/images/about/union-image.png'
import unionImageMobile from '../../assets/images/about/union-image-mobile.png'

export default function About() {

  return (
    <div className='About'>
      {/* mobile */}
      <div className='block lg:hidden'>
        <div className='w-full flex justify-center pt-8'>
          <div className='about-description w-10/12'>
            Sala urbana es un centro de espectáculos, conciertos, arte, deportes, conferencias y eventos corporativos..
          </div>
        </div>
        <div className='w-full flex justify-center pt-8'>
          <div className='w-10/12'>
            <img src={singerMobile}  alt='Singer' className='w-full' />
          </div>
        </div>
      </div>

      {/* web */}
      <div className='hidden lg:block'>
        <div className='flex w-full h-full pt-10'>
          <div className='w-2/5'>
            <img src={felixHogagen} alt='Felix Hogagen' className='image-center'/>
          </div>
          <div className='w-3/5'>
            <div className='pl-12'>
              <div className='about-description w-2/3'>
                Sala urbana es un centro de espectáculos, conciertos, arte, deportes, conferencias y eventos corporativos..
              </div>
              <div className='flex justify-end items-end h-full pt-12'>
                <div className='w-2/3'>
                  <img src={singer}  alt='Singer' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile and desktop */}
      <div className="scroll-text">
        <div className="scroll">
          <div className="m-scroll">
            <span className="text-scrolled-content">
              ¡Un venue completamente flexible!
            </span>
            <span className="text-scrolled-content">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¡Un venue completamente flexible!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
        </div>
      </div>

      <div className='flex justify-center w-full pb-8'>
        <div className='w-10/12 lg:w-1/2 flex flex-col justify-center'>
          <img src={boxer} alt='Boxer' />
          <div className='boxer-description pt-4'>
            Este espacio es ideal para cualquier tipo de eventos.
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className='block lg:hidden'>
        <div className='bg-union pb-8'>
          <div className='flex justify-center pt-8 pb-6'>
            <div className='w-10/12'>
              <div className='union-description'>
                Un lugar donde la diversidad cobra vida
              </div>
            </div>
          </div>
          <div className='flex justify-center w-full py-4'>
            <div className='w-10/12'>
              <img src={unionImageMobile} alt='concert' className='w-full' />
            </div>
          </div>
          <div className='flex justify-center pt-6'>
            <div className='w-10/12'>
              <div className='union-description'>
                Y la música y el arte se entrelazan
              </div>
              <div className='pb-0 md:pb-24'>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>

      {/* desktop */}
      <div className='hidden lg:block'>
        <div className='bg-union relative flex justify-center pt-24'>
          <div className='absolute top-28 w-full'>
            <div className='w-7/12 flex justify-end'>
              <div className='w-9/12'>
                <div className='union-description'>
                  Un lugar donde la diversidad cobra vida
                </div>
              </div>
            </div>
          </div>
          <div className='w-11/12'>
            <img src={unionImage} alt='concert' className='w-full'/>
          </div>
          <div className='absolute bottom-custom w-full flex justify-end'>
            <div className='w-7/12'>
              <div className='union-description'>
                Y la música y el arte se entrelazan
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
