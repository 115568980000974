import { Fragment, useRef, useState } from 'react'
// import { Dialog, Transition } from '@headlessui/react'
import { Dialog, Transition } from '@headlessui/react'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import './Dialogs.css'

export default function Dialogs({imageItems = [], open, handleClose, startPosition}) {

  const cancelButtonRef = useRef(null)
  const breakpoints = {
    1024: {
      perPage: 1,
    },
    768: {
      perPage: 1,
    },
    640: {
      perPage: 1,
    },
  };
  
  const options = {
    type: 'loop', // Enable loop playback
    perPage: 1, // Show 1 slides at a time
    arrows: true, // Show navigation arrows
    breakpoints,
    perMove   : 1,
    cloneStatus:false,
    start: startPosition,
    pagination:false,
  };
  return (
    <div className='Dialogs relative z-40'>
      <div className='relative z-50'>
        <Transition appear show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={handleClose}>
          
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="modal-content h-full transform overflow-hidden p-6 transition-all">
                  <div className='flex justify-end w-full p-5'>
                    <button type='button' className='text-black font-bold text-xl bg-white opacity-60 hover:opacity-55 rounded-3xl w-[30px] h-[30px] text-center' onClick={handleClose}>x</button>
                  </div>
                    <Splide options={options}>
                      {
                        imageItems.map((item, index) => (
                          <SplideSlide key={ index } >
                            <img src={item} alt={`gallery item ${index}`} className='w-auto h-[300px] md:h-[700px] mx-auto'/>
                          </SplideSlide>
                        ))
                      }
                    </Splide>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      
      
      {/* <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <Splide options={options}>
                  {
                    imageItems.map((item, index) => (
                      <SplideSlide key={ index } >
                        <img src={item.image} alt={item.title} className='w-full object-cover'/>
                      </SplideSlide>
                    ))
                  }
                </Splide>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </div>

  )
}