import React from "react";
import "./Slider.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

export default function SliderDest({ items = [], handleClick }) {
  const breakpoints = {
    1675: {
      perPage: 3,
    },
    1555: {
      perPage: 3,
    },
    1100: {
      perPage: 2,
    },
    900: {
      perPage: 1,
    },
    768: {
      perPage: 1,
    },
    640: {
      perPage: 1,
    },
  };

  const options = {
    type: "loop", // Enable loop playback
    perPage: 1, // Show 3 slides at a time
    arrows: true, // Show navigation arrows
    perMove: 1,
    pagination: false,
    cloneStatus: false,
    autoplay: true,
    gap: "3rem",
    autoWidth: true,
    // trimSpace: false,
    focus: "center",
    breakpoints,
  };

  return (
    <div className="Slider">
      <Splide options={options}>
        {items.map((slide, index) => (
          <SplideSlide key={index}>
            <div className="flex flex-col items-center">
              <div className="" onClick={() => handleClick(slide?.event)}>
                <div className=" ">
                  <img
                    src={slide.image}
                    alt={slide.title}
                    className="h-[558px] object-cover mh-cus"
                  />
                </div>
              </div>
              <div className="mt-2">&nbsp;</div>
              <div className="flex flex-col items-center justify-between w-80 min-h-[10px] text-center">
                <div
                  className="mx-auto slide-title block break-words  text-center min-h-[48px]"
                  style={{ fontSize: "1.4rem", minHeight: "70px" }}
                >
                  {slide.title}
                </div>
                {slide.buy && (
                  <a
                    href={slide.buy}
                    className="mx-auto btn-buy w-[max-content]"
                  >
                    Comprar Boletos
                  </a>
                )}
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
