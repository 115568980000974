import './App.css';
import Header from './components/Header/Header';
import Eventos from './components/Eventos/Eventos';
import About from './components/About/About';
import Mision from './components/Mision/Mision';
import Caracteristicas from './components/Caracteristicas/Caracteristicas';
import Servicios from './components/Servicios/Servicios';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import './components/Eventos/Eventos.css'
import PastEvents from './components/Eventos/PastEvents/PastEvents'
import Faq from './components/Faq/Faq';

function App() {
  return (
    <div className="App">
      <main>
        <Header />

        <section id='next'>
          <Eventos />
        </section>
        
        <section id='about'>
          <About />
        </section>

        <section id='vision'>
          <Mision />
        </section>
        
        <section id='caracteristicas'>
          <Caracteristicas />
        </section>

        <section id='services'>
          <Servicios />
        </section>
        
        <section id='past-events'>
          <PastEvents/>
        </section>

        <section id='faq'>
          <Faq/>
        </section>

        <section id="contact">
          <Contact />
        </section>
        

        <Footer />

      </main>
    </div>
  );
}

export default App;
