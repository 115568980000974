import React from 'react'
import './Caracteristicas.css'
import exposicionMobile from '../../assets/images/caracteristicas/exposicion-mobile.png'
import singerMobile from '../../assets/images/caracteristicas/singer-mobile.png'

import exposicion from '../../assets/images/caracteristicas/exposicion.png'
import singer from '../../assets/images/caracteristicas/singer.png'
import capacidad from '../../assets/images/caracteristicas/capacidad.png'

export default function Caracteristicas() {
  return (
    <div className='Caracteristicas'>
      <div className='pt-12'>
        <div className='flex justify-center w-full'>
          <div className='w-10/12 lg:w-6/12 title'>
            ¿POR QUÉ ELEGIR SALA URBANA?
          </div>
        </div>

        {/* mobile */}
        <div className='block lg:hidden'>
          <div className='flex justify-center pt-8'>
            <div className='subtitle orange'>
              Flexibilidad
            </div>
          </div>

          <div className='flex justify-center w-full'>
            <div className='w-10/12'>
              <div className='description pt-2'>
                Adaptamos el espacio, equipo y logística de acuerdo con las necesidades de tu evento.
              </div>
              <div className='pt-6'>
                <img src={exposicionMobile} alt='Exposición' className='w-full' />
              </div>
            </div>
          </div>

          <div className='flex justify-center pt-8'>
            <div className='subtitle yellow'>
              Aforo
            </div>
          </div>

          <div className='flex justify-center w-full'>
            <div className='w-10/12'>
              <div className='description pt-2'>
                Distintos formatos dependiendo las necesidades de cada evento: auditorio, banquete, cocktail, concierto, etc.
              </div>
              <div className='pt-6'>
                <img src={singerMobile} alt='Exposición' className='w-full' />
              </div>
            </div>
          </div>


          <div className='flex justify-center pt-8'>
            <div className='subtitle green'>
              Ubicación
            </div>
          </div>

          <div className='flex justify-center w-full pb-8'>
            <div className='w-10/12'>
              <div className='description pt-2'>
                Blvd. Toluca #115 Col. El Conde, Naucalpan de Juárez Edo. de México
              </div>
            </div>
          </div>

          
        </div>

        {/* desktop */}
        <div className='hidden lg:block'>
          <div className='w-full flex pt-12'>
            <div className='w-7/12'>
              <div className='flex justify-center'>
                <div className='md:w-1/2'>
                  <div className='subtitle orange'>
                    Flexibilidad
                  </div>
                  <div className='description pt-2 md:w-3/4'>
                    Adaptamos el espacio, equipo y logística de acuerdo con las necesidades de tu evento.
                  </div>
                </div>
              </div>

              <div className='w-full pt-6'>
                <img src={exposicion} alt='Exposición' className='w-full' />
              </div>

              <div className='flex justify-center pt-8'>
                <div className='w-8/12'>
                  <div className='subtitle green'>
                    Ubicación
                  </div>
                  <div className='description pt-2'>
                    Blvd. Toluca #115 Col. El Conde, Naucalpan de Juárez Edo. de México
                  </div>
                </div>
              </div>

              <div className='flex justify-end pt-6'>
                <div className='w-10/12'>
                  <img src={capacidad} alt='Capacidad' className='w-full' />
                </div>
              </div>

            </div>
            <div className='w-5/12 relative flex justify-end'>
              <div className='w-10/12 absolute bottom-0'>
                <div className='subtitle yellow'>
                  Aforo
                </div>
                <div className='description pt-2 md:w-2/3'>
                  Distintos formatos dependiendo las necesidades de cada evento: auditorio, banquete, cocktail, concierto, etc.
                </div>
                <div className='pt-6'>
                  <img src={singer} alt='Exposición' className='w-full' />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
