import React, { useEffect, useState } from "react";
import "./Slider.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import SliderDest from "./SliderDest";

export default function Slider({ items = [], handleClick }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const breakpoints = {
    1024: {
      perPage: 3,
    },
    768: {
      perPage: 1,
    },
    640: {
      perPage: 1,
    },
  };

  const options = {
    type: "loop", // Enable loop playback
    perPage: 4, // Show 3 slides at a time
    arrows: true, // Show navigation arrows
    perMove: 1,
    pagination: false,
    cloneStatus: false,
    autoplay: true,
    breakpoints,
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) {
    return (
      <div className="Slider">
        <Splide options={options}>
          {items.map((slide, index) => (
            <SplideSlide key={index}>
              <div className="flex justify-center items-center">
                <div
                  className="image-container"
                  onClick={() => handleClick(slide.event)}
                >
                  <div className="flex justify-center items-end">
                    <img
                      src={slide.image}
                      alt={slide.title}
                      className="w-full h-[557px] object-contain mh-cus"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-8 lg:pt-0">
                <div className="slide-title pt-4">{slide.title}</div>
              </div>
              <div className="mt-5">&nbsp;</div>
              {slide.buy && (
                <a href={slide.buy} className="w-60 btn-buy mx-auto ">
                  Comprar Boletos
                </a>
              )}
            </SplideSlide>
          ))}
        </Splide>
      </div>
    );
  }

  return <SliderDest items={items} handleClick={handleClick} />;
}
