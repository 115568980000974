import Accordion from './Accordion'
import checkIcon from '../../assets/images/check.svg'
import './Faq.css'

export default function Faq() {
  const answer1Value = (<>Contamos con servicio de Valet Parking. Tiene un costo de $200 pesos y está disponible desde 2 hrs antes del inicio del evento.</>)
  const answer2Value = (<>Sí, el costo depende de los organizadores del evento.</>)
  const answer3Value = (
  <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
    <p><img src={checkIcon} alt="check" className='inline'/> Medicamento con receta.</p>
    <p><img src={checkIcon} alt="check" className='inline'/> Tapones para oídos.</p>
    <p><img src={checkIcon} alt="check" className='inline'/> Baterías externas para celulares.</p>
    <p><img src={checkIcon} alt="check" className='inline'/> Sombreros/Gorras.</p>
    <p><img src={checkIcon} alt="check" className='inline'/> Vapes y cajetilla de cigarros cerrada.</p>
  </div>
  )

  const answer4Value = (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
      <p><span className='text-white'>✖︎</span> Alimentos y bebidas</p>
      <p><span className='text-white'>✖︎</span> Mascotas</p>
      <p><span className='text-white'>✖︎</span> Láser</p>
      <p><span className='text-white'>✖︎</span> Bastones selfie</p>
      <p><span className='text-white'>✖︎</span> Cámaras fotográficas o de video</p>
      <p><span className='text-white'>✖︎</span> Banderas o banderines con astas</p>
      <p><span className='text-white'>✖︎</span> Objetos punzantes o cortantes</p>
      <p><span className='text-white'>✖︎</span> Armas de fuego, blancas o contundentes</p>
      <p><span className='text-white'>✖︎</span> Objetos que impidan la visibilidad del público, como mantas, carteles, flores y globos</p>
      <p><span className='text-white'>✖︎</span> Mochilas, maletas, carriolas y  objetos que obstaculicen la comodidad de cualquier espectador. (Contamos con guardarropa en caso de requerirlo).</p>
    </div>
    )

  return (
    <div className="faq w-full pt-5 lg:mt-24 flex-col">
      <div className='w-1/2 my-8 mx-auto'>
        <div className='flex title justify-center'>
          PREGUNTAS FRECUENTES
        </div>
      </div>
      <div className="pr-5 md:pr-24">
        <Accordion title="¿Tienen estacionamiento?" answer={answer1Value} className={`bg-[#FFE24C] my-5`}/>
        <Accordion title="¿Tienen servicio de guardaropa?" answer={answer2Value} className={`bg-[#ABDE5B] my-5`}/>
        <Accordion title="¿Cuáles son los objetos permitidos?" answer={answer3Value} className={`bg-[#5991FF] my-5`}/>
        <Accordion title="¿Cuáles son los objetos no permitidos?" answer={answer4Value} className={`bg-[#EB7147] my-5`}/>
      </div>
    </div>
  )
} 