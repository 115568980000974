import React from 'react'
import './Header.css'
import Menu from '../Menu/Menu'
import heroText from '../../assets/images/hero-text.svg'

export default function Header() {
  return (
    <div className='Header'>
      <div>
        <Menu />
        <div className='absolute bottom-10 flex justify-center w-full'>
          <img src={heroText} alt='Sala Urbana' className='m-auto logo-text' />
        </div>
      </div>
    </div>
  )
}
