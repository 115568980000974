import React from "react";
import "./NextEvents.css";
import Slider from "../../Slider/Slider";

import Miura from "../../../assets/images/nextEvents/miura.png";
import sasha from "../../../assets/images/nextEvents/sashadg.png";
import sodaInfinito from "../../../assets/images/nextEvents/INFINITO TOUR MEXICO.png";
import paulVanDyk from "../../../assets/images/nextEvents/Paul V.png";
import WeAreLost from "../../../assets/images/nextEvents/we-are-lost.png";
import Zapravka from "../../../assets/images/nextEvents/zapravka.png";
import Tandran from "../../../assets/images/nextEvents/tandran.png";
import Toni from "../../../assets/images/nextEvents/tony-dize.png";
import festivalxxl from "../../../assets/images/nextEvents/festivalxxl.png";
import Warcry from "../../../assets/images/nextEvents/warcry.png";
import TWEEKAZ from "../../../assets/images/nextEvents/DA_TWEEKAZ.png";

const nextEvents = [
  {
    image: Warcry,
    title: "Warcry",
    buy: "https://warcry-daimon-tour-mexico-part-ii.boletia.com/",
  },
  {
    image: TWEEKAZ,
    title: "DA TWEEKAZ",
    buy: "https://www.passline.com/eventos/da-tweekaz-mexico-city",
  },
  {
    image: WeAreLost,
    title: "We are lost",
    buy: "https://www.ticketfairy.com/event/we-are-lost",
  },
  {
    image: Zapravka,
    title: "Zapravka",
    buy: "https://www.passline.com/eventos/zapravka-mexico-city-2025?fbclid=PAZXh0bgNhZW0CMTEAAab8hEVcXZDAf4sYPpQH3Xzey132PfXOOeNh_Vrm9hGDb64SjcMtKDk96JY_aem_83KeHwPmUXxPHJjxxH2Kfg",
  },
  {
    image: Tandran,
    title: "TANDRAH",
    buy: "https://www.ticketfairy.com/event/off-looloo-presents-taendrah?fbclid=PAZXh0bgNhZW0CMTEAAaZx7aOBHY_oi-dWuAaga3VF8JxTdnW46HqsEAWW-rCwZocu1-KAURogbE8_aem_WZ4zBPlFRoB6HRVNVyzTpg",
  },
  {
    image: sasha,
    title: "Sasha & John Digweed",
    buy: "https://sd25.boletia.com",
  },
  {
    image: Toni,
    title: "Tony Dize",
    buy: "https://tony-dize-en-concierto-sala-urbana-sabado-mayo-3-2025.boletia.com/?fbclid=PAZXh0bgNhZW0CMTEAAaY9vUD1I3u5SoAP830AE8Xpnox8_QypeX6ANjqq-09YpK0k3I_GPs1L9nY_aem_sZRL-0E_5B5csiVd1DHEvg",
  },
  {
    image: festivalxxl,
    title: "Festival XXL ",
    buy: "https://es.ra.co/events/2104322?fbclid=PAZXh0bgNhZW0CMTEAAaZVAR1L-2MtBZAMrMAya_e5fU-AUDalWptY8NcUv8G9PdZ2B6dU4TIsPhI_aem_EaGBro7Tkmg-QZEDOH3Mhw",
  },
];

export default function NextEvents() {
  return (
    <div className="NextEvents">
      <Slider
        items={nextEvents}
        handleClick={() => console.log("do nothing")}
      />
    </div>
  );
}
