import React from 'react'
import './Contact.css'

import dj from '../../assets/images/dj.png'

export default function Contact() {
  return (
    <div className='Contact pt-5 lg:mt-24'>
      <div className='w-full flex justify-center'>
        <div className='w-10/12 lg:11/12 my-8'>
          <div className='title'>
            ¿QUIERES HACER UN EVENTO EN SALA URBANA?
          </div>
          <div className='subtitle py-4 block lg:hidden'>
            Solicita una cotización
          </div>
        </div>
      </div>

      <div className='flex w-full gap-0 lg:gap-4'>
        <div className='hidden lg:block pt-0 lg:pt-12 w-7/12'>
          <img src={dj} alt='dj' className='w-full' />
        </div>
        <div className='pt-0 lg:pt-12 w-full lg:w-5/12'>
          <div className='subtitle hidden lg:block'>
            Solicita una cotización
          </div>
          <div className='form flex justify-center w-full'>
            <div className='w-10/12'>
              <div className='pt-4 lg:pt-6'>
                <input type='text' name='name' id='name' placeholder='Nombre' />
              </div>
              <div className='pt-4 lg:pt-6'>
                <input type='text' name='email' id='email' placeholder='Correo' />
              </div>

              <div className='pt-4 lg:pt-6'>
                <textarea name='message' id='message' placeholder='Mensaje' rows={5}></textarea>
              </div>

              <div className='flex justify-center lg:justify-start py-4 lg:py-6 md:py-6'>
                <button className='btn-yellow'>
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}
