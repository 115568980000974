import React from 'react'
import './Footer.css'

import salaUrbana from '../../assets/images/sala-urbana.png'
import salaUrbanaMobile from '../../assets/images/sala-urbana-mobile.svg'
import facebook from '../../assets/images/footer/facebook.svg'
import socialNetwork2 from '../../assets/images/footer/social-network2.svg'
import whatsapp from '../../assets/images/footer/whatsapp.svg'

export default function Footer() {
  return (
    <div className='Footer'>

      <div className='flex justify-center'>
        {/* mobile */}
        <div className='block lg:hidden w-10/12'>
          <div className='flex justify-center w-full pt-8 pb-6'>
            <div className='w-10/12'>
              <img src={salaUrbanaMobile} alt='Logo Sala Urbana' className='w-full' />
            </div>
          </div>

          <div className='grid grid-cols-2'>
          <div className='text-center footer-menu-items'>
              <a href='#next'>
                PROXIMOS EVENTOS
              </a>
            </div>
            <div className='text-center footer-menu-items'>
              <a href='#vision'>
                NUESTRA VISIÓN
              </a>
            </div>

            <div className='text-center footer-menu-items pt-4'>
              <a href='#services'>
                SERVICIOS
              </a>
            </div>
            <div className='text-center footer-menu-items pt-4'>
              <a href='#contact'>
                CONTACTO
              </a>
            </div>
          </div>

          <div className='flex justify-center pt-4'>
            <div className='w-6/12 md:w-5/12'>
              <div className='grid grid-cols-3 gap-2 md:gap-4'>
                <div className='social-network'>
                  <a href='https://www.facebook.com/salaurbanavenue' alt='Facebook'>
                    <img src={facebook} alt='Facebook' className='w-full' />
                  </a>
                </div>
                <div className='social-network'>
                  <a href='http://instagram.com/salaurbanavenue/' alt='Instagram'>
                    <img src={socialNetwork2} alt='Otra'  className='w-full' />
                  </a>
                </div>
                <div className='social-network'>
                  <a href='https://wa.me/525544499516' alt='whatsapp'>
                    <img src={whatsapp} alt='Whatsapp'  className='w-full' />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='flex justify-center py-6'>
            <div className='w-10/12'>
              <div className='powered-by'>
                Sitio creado por <b>WEIRD FISHES</b> 2024
              </div>
            </div>
          </div>
        </div>

        {/* desktop */}
        <div className='hidden lg:block w-10/12 py-20'>
          <div className='w-full flex justify-center items-center h-100'>
            <div className='w-11/12 flex'>
              <div className='w-4/12'>
                <img src={salaUrbana} alt='Sala Urbana' className='w-full' />
                <div className='powered-by pt-6'>
                  Sitio creado por <b>WEIRD FISHES</b> 2024
                </div>
              </div>

              <div className='w-4/12 flex justify-center mx-auto'>
                <div className='text-start'>
                  <div className='text-start footer-menu-items'>
                    <a href='#next'>
                     PROXIMOS EVENTOS
                    </a>
                  </div>
                  <div className='text-start footer-menu-items pt-4'>
                    <a href='#services'>
                      SERVICIOS
                    </a>
                  </div>
                </div>
              </div>

              <div className='w-4/12 flex justify-end'>
                <div>
                  <div className='text-start footer-menu-items'>
                    <a href='#vision'>
                      NUESTRA VISIÓN
                    </a>
                  </div>
                  <div className='text-start footer-menu-items pt-4'>
                    <a href='#contact'>
                      CONTACTO
                    </a>
                  </div>
                  <div className='flex justify-start pt-4'>
                    <div className='w-8/12'>
                      <div className='grid grid-cols-3 gap-4'>
                        <div className='social-network'>
                          <a href='https://www.facebook.com/salaurbanavenue' alt='Facebook'>
                            <img src={facebook} alt='Facebook' className='w-full' />
                          </a>
                        </div>
                        <div className='social-network'>
                          <a href='http://instagram.com/salaurbanavenue/' alt='Instagram'>
                            <img src={socialNetwork2} alt='Otra'  className='w-full' />
                          </a>
                        </div>
                        <div className='social-network'>
                          <a href='https://wa.me/525544499516' alt='whatsapp'>
                            <img src={whatsapp} alt='Whatsapp'  className='w-full' />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}
